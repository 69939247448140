<template>
    <admin-table title="周期性排班" @search="handleSearch" :table="table" :loading="loading">
        <el-table-column type="expand">
            <template slot-scope="props">
                <div style="margin-left:50px;line-height: 30px;">
                    分时设置
                    <div class="expend-times">
                        <div class="time" v-for="t in props.row.times" :key="t.ID">{{t.time}}({{t.count}})</div>
                    </div>
                </div>
            </template>
        </el-table-column>
        <el-table-column align="center"
                         v-for="(v, idx) in table.columns"
                         :prop="v.field"
                         :label="v.title"
                         :width="v.width"
                         :formatter="v.formatter"
                         :key="idx">
        </el-table-column>
        <el-table-column align="center" label="操作" width="180">
            <template slot-scope="scope">
                <el-button size="small" type="warning" icon="el-icon-edit" @click="handleEdit(scope.row)"/>
                <el-popover style="margin-left: 10px" :ref="`popover-${scope.$index}`" placement="top"
                            width="180">
                    <p>确定删除[{{scope.row.doctor}}]的记录吗？</p>
                    <div>
                        <el-button type="danger" size="mini" @click="handleDel(scope.$index, scope.row)">确定</el-button>
                        <el-button size="mini" type="default"
                                   @click="$refs[`popover-${scope.$index}`].doClose()">取消
                        </el-button>
                    </div>
                    <el-button size="small" type="danger" slot="reference" icon="el-icon-delete"></el-button>
                </el-popover>
            </template>
        </el-table-column>
    </admin-table>
</template>

<script>
    import adminTable from "@/components/tables/adminTable"

    export default {
        components: {
            adminTable
        },
        data() {
            function dateFormatter(row, column) {
                let date = row[column.property]
                return date.substring(0, 10)
            }

            function areaFormatter(t) {
                let result = ''
                t.area.forEach(e => {
                    result += `${e.name} `
                })
                return result
            }

            function weekdayFormatter(t) {
                const wds = ['周日', '周一', '周二', '周三', '周四', '周五', '周六']
                let result = ''
                t.weekday.forEach((w) => {
                    result += wds[w] + ' '
                })
                return result
            }

            function timesSum(t) {
                let result = 0
                t.times.forEach(e => {
                    result += e.count
                })
                return result
            }

            return {
                loading: false,
                table: {
                    buttons: [
                        {
                            type: 'default',
                            label: '导出Excel',
                            icon: 'el-icon-download',
                            path: '/main/worksheet/cycle/form'
                        },
                        {
                            type: 'primary',
                            label: '新建排班',
                            icon: 'el-icon-plus',
                            path: '/main/worksheet/cycle/form'
                        }
                    ],
                    search: [
                        {
                            type: 'select',
                            key: 'area_id',
                            data: [
                                {
                                    label: '全部',
                                    value: ''
                                }
                            ],
                            class: 'hidden-md-and-down',
                            placeholder: '请选择来源'
                        },
                        {
                            type: 'input',
                            key: 'keywords',
                            placeholder: '请输入关键词'
                        }
                    ],
                    columns: [
                        {title: '开始日期', field: 'start', width: '100', formatter: dateFormatter},
                        {title: '结束日期', field: 'end', width: '100', formatter: dateFormatter},
                        {title: '医生', field: 'doctor', width: ''},
                        {title: '手术室', field: 'area.name', width: '', formatter: areaFormatter},
                        {title: '手术日', field: 'weekday', width: '', formatter: weekdayFormatter},
                        {title: '时段', field: 'times.length', width: ''},
                        {title: '号数', field: 'times', width: '', formatter: timesSum},
                    ],
                    data: [],
                    total: 0
                },
            }
        },
        methods: {
            handleEdit(row) {
                let path = `/main/worksheet/cycle/form?id=${row.id}`
                this.$store.commit('setRouterHistory', {label: `手术排班 - ${row.doctor}`, path: path})
                this.$router.push({
                    path: path
                })
            },
            async handleDel(idx, row) {
                this.$refs[`popover-${idx}`].doClose()
                const resp = await this.$http.delete(`/surgery/worksheet/${row.id}`)
                if (resp.data.code === 200) {
                    this.$message.success(resp.data.data)
                    this.getData()
                } else {
                    this.$message.error(resp.data.msg)
                }
            },
            async getData(e) {
                const resp = await this.$http.get(`/surgery/worksheet/`, {params: e})
                this.table.data = resp.data.data.data
                this.table.total = resp.data.data.total
            },
            handleSearch(e) {
                this.getData(e)
            },
        },
        async mounted() {
            this.getData()
        }
    }
</script>

<style lang="scss" scoped>
</style>
